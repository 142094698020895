import React, { useEffect, useState } from 'react'
import AnalogClock from 'analog-clock-react'
import { Grid, Typography } from '@material-ui/core'

const Clock = () => {
  const [quartersTime, setQuartersTime] = useState('')

  useEffect(() => {
    updateQuartersTime()
    const updateTimeInterval = setInterval(updateQuartersTime, 1000)

    return () => clearInterval(updateTimeInterval)
  }, [])

  const options = {
    width: '300px',
    border: true,
    borderColor: '#26381C',
    baseColor: '#829C42',
    centerColor: '#26381C',
    centerBorderColor: '#fff',
    handColors: {
      second: '#5C7329',
      minute: '#fff',
      hour: '#fff',
    },
  }

  const updateQuartersTime = () => {
    const date = new Date()
    const quarters =
      Math.round((date.getHours() * 4 + date.getMinutes() / 15) * 10) / 10

    if (quarters === 1) {
      return setQuartersTime(`Són 1 quart d'hora`)
    }

    setQuartersTime(`Són els ${quarters} quarts d'hora`)
  }

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <AnalogClock {...options} />
      </Grid>

      <Grid item>
        <Typography variant="h6" component="p">
          {quartersTime}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Clock

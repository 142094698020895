import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Textfit } from 'react-textfit'
import './heading-quote.scss'

const phrases = [
  { text: "Un quart d'hora és un quart d'hora", author: 'El Pony Pisador' },
  {
    text: "Un quart d'hora dura un quart d'hora i mitja hora, dos quarts d'hora, i una hora són quatre quarts d'hora perquè un quart d'hora dura un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Qinze minuts són un quart d'hora, trenta minuts són dos quarts d'hora, trenta-un minuts són dos quarts d'hora i un minut, i tres quarts d'hora, quaranta-cinc minuts",
    author: 'Miquel Pérez',
  },
  {
    text: "Un quart d'hora és la meitat de mitja hora, i mitja hora és la meitat d'una hora, una hora és la meitat de dues hores i dues hores és el doble d'una hora",
    author: 'Ramon Anglada',
  },
  {
    text: "Cinc minuts i cinc minuts i cinc minuts fan un quart d'hora. Deu minuts i quatre minuts i un minut també fan un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Un minut i un altre minut, i un altre minut, i un altre minut, i onze minuts més, si no erro fan un quart d'hora",
    author: 'Ramon Anglada',
  },
  {
    text: "Cinquanta minuts i cinc minuts i cinc minuts fan una hora, i si li restes quaranta-cinc el que et queda és un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Una síndria i un camió i un ocell no són un quart d'hora, però si treus la síndria, el camió i l'ocell llavors sí, si li poses 15 minuts tens un quart d'hora",
    author: 'Guillem Codern',
  },
  {
    text: "Si l'hora es digués ocell una hora seria un ocell, i la quarta part d'un ocell també seria un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Quatre quarts d'hora és una hora, tres quarts d'hora és hor, dos quarts d'hora és ho, i un quart d'hora és h",
    author: 'Guillem Codern',
  },
  {
    text: "El temps és relatiu, el temps és subjectiu, però el que és molt objectiu és el que dura un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Deu minuts i deu minuts i deu minuts fan mitja hora, que entre dos fa un quart d'hora, i entre tres fa deu minuts",
    author: 'Ramon Anglada',
  },
  {
    text: "Un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut i un minut fan un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Al metro, quan esperes, hi ha una pantalla on diu el temps. Normalment diu com dos minuts, però si és de nit saps que serà un quart d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "Un quart d'hora és perillós quan el poses al despertador i li dius que t'avisi en un quart d'hora, i després un quart d'hora, un altre quart d'hora, un altre quart d'hora i de cop t'has adormit",
    author: 'Ramon Anglada',
  },
  {
    text: "Un quart de llom és sinònim d'un quart d'hora. Has de canviar la L per la H, l'altre L per la O, la O per la R i la M per la A",
    author: 'Guillem Codern',
  },
  {
    text: "Les partícules subatòmiques que són els quarks d'hora si li canvies la K per la T es converteixen en quarts d'hora",
    author: 'Miquel Pérez',
  },
  {
    text: "L'altre dia vaig anar a l'estany on hi havia un ànec molt savi. El vaig mirar durant quinze minuts i efectivament havia passat un quac d'hora",
    author: 'Guillem Codern',
  },
  {
    text: "Un quart d'hora són 10 minuts si estàs contant en base 14",
    author: 'Guillem Codern',
  },
  {
    text: "El sistema sexagesimal ens ha portat moltes coses bones, però la millor cosa que ens ha portat és el nostre amic, el quart d'hora",
    author: 'Miquel Pérez',
  },
]

const HeadingQuote = () => {
  const getRandomPhrase = useCallback(() => {
    return phrases[Math.floor(Math.random() * phrases.length)]
  }, [])

  const [selectedPhrase, setSelectedPhrase] = useState(getRandomPhrase)
  const [visible, setVisible] = useState(true)

  const selectRandomPhrase = useCallback(() => {
    setVisible(false)

    const timeout = setTimeout(() => {
      setSelectedPhrase(getRandomPhrase())
      setVisible(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [getRandomPhrase])

  useEffect(() => {
    const randomPhraseInterval = setInterval(selectRandomPhrase, 15000)

    return () => clearInterval(randomPhraseInterval)
  }, [selectRandomPhrase])

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      spacing={2}
      style={{ height: 130 }}
      className={`heading-quote ${!visible && 'hide'}`}
    >
      {selectedPhrase && (
        <>
          <Grid item>
            <Typography variant="h4" component="h1">
              <em>
                <Textfit
                  mode="multi"
                  style={{ maxHeight: 70, textAlign: 'center' }}
                >
                  &ldquo;{selectedPhrase.text}&rdquo;
                </Textfit>
              </em>
            </Typography>
          </Grid>
          <Grid
            item
            style={{ marginLeft: 'auto', marginRight: 'min(100px, 10vw)' }}
          >
            <Typography variant="subtitle1">
              - {selectedPhrase.author}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default HeadingQuote

import React, { useCallback, useEffect, useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'

const PeriodCalculator = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [quartersElapsed, setQuartersElapsed] = useState()

  const calculateQuartersElapsed = useCallback(() => {
    if (!selectedDate) {
      return
    }

    const now = new Date()
    const msElapsed = now - selectedDate
    setQuartersElapsed(Math.floor(msElapsed / 1000 / 60 / 15))
  }, [selectedDate])

  useEffect(() => {
    calculateQuartersElapsed()
  }, [calculateQuartersElapsed])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Tria una data i hora per veure quants quarts d'hora han passat des
          d'aquell moment.
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <DateTimePicker
            renderInput={props => <TextField {...props} />}
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            color="primary"
            style={{ paddingBottom: 24 }}
          >
            {quartersElapsed > 0 ? (
              <>
                Han passat <strong>{quartersElapsed}</strong> quarts d'hora
              </>
            ) : (
              quartersElapsed < 0 && (
                <>
                  Falten <strong>{-quartersElapsed}</strong> quarts d'hora
                </>
              )
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PeriodCalculator

import React from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import Clock from './components/clock'
import PeriodCalculator from './components/period-calculator'
import HeadingQuote from './components/heading-quote'

const App = () => (
  <Container className="container">
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={5}
    >
      <Grid item>
        <HeadingQuote />
      </Grid>

      <Grid item>
        <Clock />
      </Grid>

      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction="column" justify="center" spacing={2}>
              <Grid item>
                <Typography variant="h5" component="h2">
                  Calculador de temps en quarts d'hora
                </Typography>
              </Grid>

              <Grid item>
                <PeriodCalculator />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item container direction="row" justify="center" spacing={2}>
        <Grid item>
          <Typography variant="body2">
            Inspirat per{' '}
            <Link
              href="https://www.youtube.com/watch?v=yIH8X57TFD8"
              target="_blank"
              rel="noreferrer"
            >
              El Pony Pisador
            </Link>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2">
            Autèntica{' '}
            <Link
              href="https://play.google.com/store/apps/details?id=coop.castell.elquartdhora"
              target="_blank"
              rel="noreferrer"
            >
              app del Quart d'Hora per a Android
            </Link>{' '}
            per jordi_ct
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Container>
)

export default App
